<template>
  <v-select
    v-model="currencyValue"
    :items="currencies"
    item-title="name"
    item-value="code"
    class="rounded-pill overflow-hidden"
    variant="filled"
    density="compact"
    hide-details
  >
    <template #selection>
      <span class="text-3 font-semibold">
        {{ currency.symbol }} {{ currency.code }}
      </span>
    </template>
  </v-select>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCurrencies } from "@/modules/sales/src/runtime/utils";

export default defineComponent({
  name: "CurrencyDropdown",
  setup() {
    const { currencyValue, currency } = useCurrency();
    const currencies = getCurrencies();

    return {
      currencyValue,
      currency,
      currencies,
    };
  },
});
</script>
