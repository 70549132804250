<template>
  <v-btn v-bind="$props" :icon="icon" @click="handleClick" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VBtn } from "vuetify/components";
import { useThemeStore, type ThemeTypeOptions } from "../../stores/theme";

export default defineComponent({
  name: "ThemeSwitch",
  props: {
    ...VBtn.props,
  },
  setup() {
    const themes: ThemeTypeOptions[] = ["light", "dark", "system"];

    const breakpoints = useBreakpoints();
    const isDesktop = breakpoints.greaterOrEqual("md");

    const store = useThemeStore();
    const icon = computed(() => {
      if (store.theme == "light") {
        return "light_mode";
      } else if (store.theme == "dark") {
        return "dark_mode";
      }

      return isDesktop.value ? "monitor" : "smartphone";
    });

    const handleClick = () => {
      let next = themes.indexOf(store.theme) + 1;
      if (next >= themes.length) {
        next = 0;
      }
      store.setTheme(themes[next]);
    };

    return {
      handleClick,
      icon,
    };
  },
});
</script>
