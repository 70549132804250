<template>
  <footer class="footer">
    <div class="space-y-8">
      <nav>
        <slot
          v-bind="{ forBizLinks, resourceLinks, companyLinks, contactPhone }"
        />
      </nav>
      <v-container
        class="flex flex-col justify-between gap-3 text-xs sm:gap-5 md:items-center lg:flex-row"
      >
        <div class="flex flex-wrap gap-1">
          <div class="whitespace-nowrap opacity-[0.8] md:mr-10">
            Copyright © {{ year }} Orie. All rights reserved.
          </div>
          <div class="flex justify-center gap-1 sm:justify-start">
            <template v-for="(link, i) in legalLinks" :key="`legal-${i}`">
              <span v-if="i" class="font-300 opacity-40 mx-1">|</span>
              <RouteLink v-bind="link" class="hover:text-orange" />
            </template>
          </div>
        </div>
        <div
          class="text-3.1 lt-md:my-1 opacity-[0.4] flex-center justify-between md:justify-start"
        >
          <span class="mr-5"> Handcrafted with ❤️ </span>
          <span class="flex-center">
            <ThemeSwitch class="mr-3" size="x-small" variant="text" />
            <CurrencyDropdown />
          </span>
        </div>
        <div
          class="flex items-center justify-center md:justify-right gap-2 sm:gap-3"
        >
          <a
            v-if="social.twitter"
            class="text-inherit transition hover:opacity-70"
            target="_blank"
            rel="me nofollow noopener noreferrer"
            aria-label="X (formerly Twitter)"
            :href="social.twitter"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
              />
            </svg>
          </a>
          <a
            v-if="social.linkedin"
            class="text-inherit transition hover:opacity-70"
            target="_blank"
            rel="me nofollow noopener noreferrer"
            aria-label="LinkedIn"
            :href="social.linkedin"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                fill="currentColor"
                d="M0 1.29C0 .576.592 0 1.322 0h15.356C17.408 0 18 .577 18 1.29v15.42c0 .713-.592 1.29-1.322 1.29H1.322C.592 18 0 17.423 0 16.71zm5.56 13.778V6.94h-2.7v8.128zM4.21 5.83c.943 0 1.529-.623 1.529-1.404-.017-.798-.585-1.404-1.51-1.404S2.7 3.629 2.7 4.426c0 .78.586 1.404 1.493 1.404zm5.522 9.238v-4.54c0-.242.018-.485.09-.658.195-.485.64-.988 1.386-.988.978 0 1.368.745 1.368 1.838v4.348h2.701v-4.662c0-2.497-1.331-3.658-3.109-3.658-1.433 0-2.076.787-2.436 1.342v.028h-.018l.018-.028V6.94h-2.7c.034.763 0 8.128 0 8.128z"
              />
            </svg>
          </a>
          <a
            v-if="social.facebook"
            class="text-inherit transition hover:opacity-70"
            target="_blank"
            rel="me nofollow noopener noreferrer"
            aria-label="Facebook"
            :href="social.facebook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                fill="currentColor"
                d="M18 9.055C18 4.054 13.97 0 9.001 0 4.03.001 0 4.054 0 9.056 0 13.575 3.291 17.321 7.593 18v-6.327H5.309V9.056h2.286V7.06c0-2.27 1.344-3.522 3.4-3.522.985 0 2.014.176 2.014.176v2.228h-1.135c-1.117 0-1.466.698-1.466 1.415v1.698h2.495l-.398 2.616h-2.098V18C14.71 17.319 18 13.574 18 9.055z"
              />
            </svg>
          </a>
          <a
            v-if="social.instagram"
            class="text-inherit transition hover:opacity-70"
            target="_blank"
            rel="me nofollow noopener noreferrer"
            aria-label="Instagram"
            :href="social.instagram"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              width="20"
              height="20"
              fill="none"
            >
              <path
                fill="currentColor"
                d="M17.8 6.7c0-.9-.2-1.4-.4-1.9-.2-.6-.5-1-1-1.4-.5-.5-.9-.7-1.4-1-.5-.2-1.1-.3-1.9-.4H10c-2.1 0-2.5 0-3.3.1-.9 0-1.4.2-1.9.4-.6.2-1 .4-1.4.9-.5.4-.7.9-1 1.4-.2.6-.3 1.1-.3 2-.1.7-.1 1-.1 3.2s0 2.5.1 3.3c0 .9.2 1.4.4 1.9.2.6.5 1 1 1.4s.9.7 1.4 1c.4.2 1.1.3 1.9.4H10c2.1 0 2.4 0 3.2-.1.9 0 1.4-.2 1.9-.4.6-.2 1-.5 1.4-1 .5-.5.7-.9 1-1.4.2-.5.3-1.1.4-1.9 0-.9.1-1 .1-3.2-.1-2.1-.1-2.5-.2-3.3zm-1.4 6.5c0 .8-.2 1.2-.2 1.5-.2.4-.3.6-.6.9-.2.2-.6.5-.9.6-.3.1-.7.2-1.5.2-.9 0-1.1.1-3.2.1s-2.4 0-3.2-.1c-.8 0-1.2-.2-1.5-.2-.5-.1-.7-.2-1-.6-.2-.2-.5-.6-.6-.9-.2-.3-.3-.7-.3-1.5 0-.9-.1-1.1-.1-3.2 0-2.2 0-2.4.1-3.2 0-.8.2-1.2.2-1.5.2-.4.3-.6.6-.9.2-.2.6-.5.9-.6.5-.1.9-.3 1.7-.3.9 0 1.2-.1 3.2-.1s2.3 0 3.1.1c.8 0 1.2.2 1.5.2.4.2.6.3.9.6.3.3.5.7.6 1 .2.3.3.7.3 1.5 0 .9.1 1.1.1 3.2 0 2.2 0 2.4-.1 3.2zM10 5.9c-2.2 0-4.1 1.8-4.1 4.1s1.8 4.1 4.1 4.1 4-1.9 4-4.1-1.8-4.1-4-4.1m0 6.7c-1.4 0-2.6-1.2-2.6-2.6S8.5 7.4 10 7.4s2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6m5.1-6.8c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1"
              />
            </svg>
          </a>
          <a
            v-if="social.youtube"
            class="text-inherit transition hover:opacity-70"
            target="_blank"
            rel="me nofollow noopener noreferrer"
            aria-label="YouTube"
            :href="social.youtube"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="14"
              fill="none"
              viewBox="0 0 19 14"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M16.44 1.087c.775.208 1.38.815 1.59 1.59.548 2.212.512 6.395.01 8.641a2.253 2.253 0 0 1-1.59 1.59c-2.188.542-11.993.475-14.055 0a2.253 2.253 0 0 1-1.59-1.59C.287 9.21.323 4.751.793 2.688a2.253 2.253 0 0 1 1.59-1.59C5.31.489 15.398.686 16.44 1.089zM7.682 4.301l4.7 2.696-4.7 2.696z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            v-if="social.whatsapp"
            class="text-inherit transition hover:opacity-70"
            target="_blank"
            rel="me nofollow noopener noreferrer"
            aria-label="WhatsApp"
            :href="social.whatsapp"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
              />
            </svg>
          </a>
          <a
            v-if="social.tiktok"
            target="_blank"
            class="text-inherit transition hover:opacity-70"
            rel="me nofollow noopener noreferrer"
            aria-label="TikTok"
            :href="social.tiktok"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              fill="none"
              viewBox="0 0 16 18"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M11.663 0c.297 2.554 1.723 4.077 4.201 4.24v2.872c-1.436.14-2.694-.33-4.158-1.215v5.373c0 6.826-7.441 8.96-10.433 4.066-1.922-3.148-.745-8.672 5.422-8.894v3.03c-.47.076-.972.194-1.431.35-1.372.465-2.15 1.335-1.933 2.868.415 2.938 5.805 3.808 5.357-1.933V.005h2.975z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>
      </v-container>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ThemeSwitch from "@/components/elements/ThemeSwitch.vue";
import CurrencyDropdown from "@/components/layout/modules/CurrencyDropdown.vue";

export default defineComponent({
  components: { ThemeSwitch, CurrencyDropdown },
  setup() {
    const config = useRuntimeConfig();
    const { links, social } = config.public;

    const forBizLinks = [
      { text: "Why sell with Orie", to: { name: "sell" } },
      { text: "Events", to: { name: "sell-events" } },
      { text: "Appointments", to: { name: "sell-appointments" } },
      { text: "Commerce", to: { name: "sell-commerce" } },
      { text: "Admin Dashboard", href: links.dashboard, target: "_blank" },
      // Orie vs Square?
    ];

    const resourceLinks = [
      { text: "Pricing", to: { name: "sell-pricing" } },
      { text: "FAQs", to: { name: "help-faqs" } },
      {
        text: "Legal",
        to: { name: "legal" },
      },
    ];

    const companyLinks = [
      { text: "Contact us", to: { name: "contact" } },
      { text: "About us", to: { name: "about" } },
      { text: "Leave a feedback", to: { name: "help-faqs" } }, // TODO: change path to help-feedback
    ];

    const legalLinks = [
      {
        text: "Privacy",
        to: { name: "legal-handle", params: { handle: "privacy" } },
        target: "_blank",
      },
      {
        text: "Terms",
        to: { name: "legal-handle", params: { handle: "terms" } },
        target: "_blank",
      },
      {
        text: "Sales",
        to: { name: "legal-handle", params: { handle: "sales" } },
        target: "_blank",
      },
      {
        text: "Seller Terms",
        to: { name: "legal-handle", params: { handle: "seller-terms" } },
        target: "_blank",
      },
    ];

    // TODO: add phone number here
    const contactPhone = "";

    return {
      forBizLinks,
      resourceLinks,
      legalLinks,
      companyLinks,
      social,
      dashboardUrl: config.public.links.dashboard,
      contactPhone,
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
});
</script>

<style lang="scss">
.footer {
  mark {
    display: inline-block;
    line-height: 0em;
    padding-bottom: 0.5em;
  }

  .list-title {
    @apply text-4 font-400 mb-2 opacity-70;
  }
}
</style>
