<template>
  <v-app id="app" full-height>
    <NoJs />
    <slot />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isFunction } from "lodash";
import NoJs from "@/components/elements/NoJs.vue";
import type { PageSeoMeta, PageSeoMetaInput } from "@/types/seo";

export default defineComponent({
  components: {
    NoJs,
  },
  setup() {
    const resolve = (input?: PageSeoMetaInput): PageSeoMeta => {
      return isFunction(input) ? input() : input || {};
    };

    const pageMeta = (data: PageSeoMeta, def: Partial<PageSeoMeta>) => {
      useHead(() => {
        return {
          title: data.title || def.title,
          titleTemplate: data.titleTemplate || def.titleTemplate,

          // ogTitle: config.app.name,
          // ogDescription: config.app.description,
          // ogSiteName: config.app.url,
          // ogImage: config.app.img,
          // ogUrl: config.app.url,
          // themeColor: colors.orange.base,
          // twitterSite: config.app.url,
          // twitterImage: config.app.img,

          meta: [
            {
              name: "description",
              content: data.description || def.description,
            },
            {
              name: "keywords",
              content: wrap(data.keywords || def.keywords).join(", "),
            },
          ],
        };
      });
    };

    const handle = () => {
      const config = useAppConfig();
      const route = useRoute();
      const keywords = seoKeywords();

      const data = resolve(route.meta.seo);
      if (route.meta.layout == "sell") {
        pageMeta(data, {
          titleTemplate: "%s - Sell with Orie AU",
          title: config.app.name,
          description: config.seo.sellDescription,
          keywords: keywords.sell.home,
        });
      } else {
        pageMeta(data, {
          title: config.app.name,
          description: config.seo.description,
          keywords: keywords.home,
        });
      }
    };

    onMounted(() => {
      handle();
      onRouteChange(handle);
    });
  },
});
</script>
